import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Register: React.FC = () => {
    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8, textAlign: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Registration Unavailable
                </Typography>
                <Typography variant="body1">
                    Registration is currently unavailable. Please contact <a href="mailto:hussain.hassam21@gmail.com">hussain.hassam21@gmail.com</a> to get access.
                </Typography>
            </Box>
        </Container>
    );
};

export default Register;
