import React, { createContext, useState, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface NotificationContextProps {
    open: (message: string, severity?: 'success' | 'error' | 'warning' | 'info') => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
    open: () => { },
});

interface Props {
    children: ReactNode;
}

export const NotificationProvider: React.FC<Props> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');

    const handleClose = () => {
        setOpen(false);
        setMessage('');
    };

    const showMessage = (msg: string, sev: 'success' | 'error' | 'warning' | 'info' = 'info') => {
        setMessage(msg);
        setSeverity(sev);
        setOpen(true);
    };

    return (
        <NotificationContext.Provider value={{ open: showMessage }}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};
