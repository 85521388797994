// src/components/Footer.tsx

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                textAlign: 'center',
            }}
        >
            <Typography variant="body2" color="text.secondary">
                © {new Date().getFullYear()} Hematology-Oncology Reference Tool. Contact us at{' '}
                <a href="mailto:hussain.hassam21@gmail.com">hussain.hassam21@gmail.com</a>.
            </Typography>
        </Box>
    );
};

export default Footer;
