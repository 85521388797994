import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { CircularProgress, Box } from '@mui/material';

interface PrivateRouteProps {
    children: JSX.Element;
    requiredRole?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRole }) => {
    const { isAuthenticated, user, isLoading } = useContext(AuthContext);
    if (isLoading) {
        // Show a loading spinner while authentication status is being determined
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated) {
        console.log("not authenticated")
        return <Navigate to="/login" />;
    }
    if (requiredRole && user?.role !== requiredRole) {
        return <Navigate to="/" />;
    }
    return children;
};

export default PrivateRoute;
