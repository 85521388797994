// src/pages/Profile.tsx

import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button, Paper, CircularProgress } from '@mui/material';
import { AuthContext } from '../components/AuthContext';
import api from '../services/api';
import { UserOut } from '../types';
import { NotificationContext } from '../components/Notification';

const Profile: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [profile, setProfile] = useState<UserOut | null>(null);
    const [name, setName] = useState<string>('');
    const { open: showNotification } = useContext(NotificationContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get('/users/me');
                setProfile(response.data);
                setName(response.data.name);
            } catch (error: any) {
                showNotification('Failed to fetch profile', 'error');
            } finally {
                setLoading(false);
            }
        };
        fetchProfile();
    }, [showNotification]);

    const handleUpdate = async () => {
        try {
            const response = await api.put(`/users/${profile?.id}`, {
                name,
                email: profile?.email,
                password: '',  // Handle password update separately if needed
                role: profile?.role
            });
            setProfile(response.data);
            showNotification('Profile updated successfully', 'success');
        } catch (error: any) {
            showNotification(error.response?.data.detail || 'Failed to update profile', 'error');
        }
    };

    if (loading) {
        return (
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (!profile) {
        return (
            <Container>
                <Typography variant="h5" component="h2" sx={{ mt: 4 }}>
                    Profile not found.
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
                <Paper sx={{ p: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        Profile
                    </Typography>
                    <TextField
                        label="Name"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        value={profile.email}
                        disabled
                    />
                    <TextField
                        label="Role"
                        fullWidth
                        margin="normal"
                        value={profile.role}
                        disabled
                    />
                    {/* Implement password change if needed */}
                    <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ mt: 2 }}>
                        Update Profile
                    </Button>
                </Paper>
            </Box>
        </Container>
    );
};

export default Profile;
