import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import api from '../services/api';
import { UserOut } from '../types';
import { NotificationContext } from '../components/Notification';

const AdminDashboard: React.FC = () => {
    const [users, setUsers] = useState<UserOut[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [role, setRole] = useState<string>('physician');
    const { open: showNotification } = useContext(NotificationContext);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users');
                setUsers(response.data);
            } catch (error) {
                showNotification('Failed to fetch users', 'error');
            }
        };
        fetchUsers();
    }, [showNotification]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setEmail('');
        setPassword('');
        setRole('physician');
    };

    const handleCreateUser = async () => {
        try {
            await api.post('/users', { name, email, password, role });
            showNotification('User created successfully', 'success');
            handleClose();
            const response = await api.get('/users');
            setUsers(response.data);
        } catch (error: any) {
            showNotification(error.response?.data.detail || 'Failed to create user', 'error');
        }
    };

    const handleDeleteUser = async (userId: number) => {
        try {
            await api.delete(`/users/${userId}`);
            showNotification('User disabled successfully', 'success');
            const response = await api.get('/users');
            setUsers(response.data);
        } catch (error) {
            showNotification('Failed to disable user', 'error');
        }
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Admin Dashboard
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mb: 2 }}>
                    Create New User
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{user.is_active ? 'Active' : 'Disabled'}</TableCell>
                                    <TableCell>
                                        {user.role !== 'admin' && user.is_active && (
                                            <Button variant="outlined" color="secondary" onClick={() => handleDeleteUser(user.id)}>
                                                Disable
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Create User Dialog */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Create New User</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Name"
                            fullWidth
                            margin="normal"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="role-select-label">Role</InputLabel>
                            <Select
                                labelId="role-select-label"
                                value={role}
                                label="Role"
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem value="physician">Physician</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleCreateUser} variant="contained" color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
};

export default AdminDashboard;
