import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button, Select, MenuItem, FormControl, InputLabel, Paper, CircularProgress, LinearProgress, List, ListItem, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import api from '../services/api';
import { TopicOut, ResourceQueryOut, ResponseStyle } from '../types';
import ReactMarkdown from 'react-markdown';

type QuestionStyle = 'general' | 'multiple_choice' | 'short_answer';

const Resources: React.FC = () => {
    const [topics, setTopics] = useState<TopicOut[]>([]);
    const [selectedTopic, setSelectedTopic] = useState<number | 'all'>('all');
    const [question, setQuestion] = useState<string>('');
    const [responseStyle, setResponseStyle] = useState<ResponseStyle>('detailed');
    const [answers, setAnswers] = useState<ResourceQueryOut[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [questionStyle, setQuestionStyle] = useState<QuestionStyle>('general');
    const [expandedContexts, setExpandedContexts] = useState<Record<number, boolean>>({});

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await api.get('/topics');
                setTopics(response.data);
            } catch (error) {
                alert('Failed to fetch topics');
            }
        };
        fetchTopics();
    }, []);

    const handleSubmit = async () => {
        if (!question.trim()) {
            alert('Please enter a question');
            return;
        }

        setIsLoading(true);
        try {
            const payload = {
                topic_id: selectedTopic === 'all' ? null : selectedTopic,
                question_text: question,
                response_style: responseStyle,
                question_style: questionStyle,
            };
            const response = await api.post('/resource-queries', payload);
            setAnswers(prevAnswers => [response.data, ...prevAnswers]);
            setQuestion('');
        } catch (error: any) {
            alert(error.response?.data.detail || 'Failed to submit question');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleContext = (answerId: number) => {
        setExpandedContexts(prev => ({
            ...prev,
            [answerId]: !prev[answerId]
        }));
    };

    const renderAnswer = (answer: ResourceQueryOut, isLatest: boolean) => (
        <>
            <Box sx={{ width: '100%', mb: 2 }}>
                <Typography
                    variant="subtitle1"
                    color="text.primary"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                >
                    Question:
                </Typography>
                <Typography variant="body1" sx={{ pl: 2 }}>
                    {answer.question_text}
                </Typography>
            </Box>

            <Box sx={{ width: '100%', mb: 1 }}>
                <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                >
                    Answer:
                </Typography>
                <Box
                    sx={{
                        pl: 2,
                        bgcolor: 'grey.50',
                        p: 2,
                        borderRadius: 1,
                    }}
                >
                    <ReactMarkdown>{answer.answer_text}</ReactMarkdown>
                </Box>
            </Box>

            {answer.context && (
                <>
                    <Button
                        onClick={() => toggleContext(answer.id)}
                        startIcon={expandedContexts[answer.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        sx={{ alignSelf: 'flex-start', mb: 1 }}
                    >
                        {expandedContexts[answer.id] ? 'Hide Medical Context' : 'Show Medical Context'}
                    </Button>
                    <Collapse in={expandedContexts[answer.id]}>
                        <Box sx={{ width: '100%', mb: 1 }}>
                            <Box
                                sx={{
                                    pl: 2,
                                    bgcolor: 'grey.100',
                                    p: 2,
                                    borderRadius: 1,
                                }}
                            >
                                <ReactMarkdown>{answer.context}</ReactMarkdown>
                            </Box>
                        </Box>
                    </Collapse>
                </>
            )}
            <Typography
                variant="caption"
                color="text.secondary"
                sx={{ alignSelf: 'flex-end', mt: 1 }}
            >
                Response Style: {answer.response_style}
                <br />
                Question Style: {answer.question_style}
            </Typography>
        </>
    );

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Answer my question
                </Typography>
                <Paper sx={{ p: 3, mb: 4, position: 'relative' }}>
                    {isLoading && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                            }}
                        />
                    )}

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="topic-select-label">Topic</InputLabel>
                        <Select
                            labelId="topic-select-label"
                            value={selectedTopic}
                            label="Topic"
                            onChange={(e) => setSelectedTopic(e.target.value as number | 'all')}
                        >
                            <MenuItem value="all">All Topics</MenuItem>
                            {topics.map((topic) => (
                                <MenuItem key={topic.id} value={topic.id}>
                                    {topic.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="question-style-label">Question Style</InputLabel>
                        <Select
                            labelId="question-style-label"
                            value={questionStyle}
                            label="Question Style"
                            onChange={(e) => setQuestionStyle(e.target.value as QuestionStyle)}
                        >
                            <MenuItem value="general">General Question</MenuItem>
                            <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
                            <MenuItem value="short_answer">Short Answer</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Your Question"
                        multiline
                        minRows={4}
                        maxRows={12}
                        fullWidth
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        sx={{
                            mb: 2,
                            '& .MuiInputBase-root': {
                                maxHeight: '50vh',
                                overflowY: 'auto'
                            }
                        }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="response-style-label">Response Style</InputLabel>
                        <Select
                            labelId="response-style-label"
                            value={responseStyle}
                            label="Response Style"
                            onChange={(e) => setResponseStyle(e.target.value as ResponseStyle)}
                        >
                            <MenuItem value="detailed">Detailed (Slower, but very detailed answer)</MenuItem>
                            <MenuItem value="compact">Compact (Fast, but much less detailed)</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        sx={{ minWidth: 100 }}
                    >
                        {isLoading ? (
                            <>
                                <CircularProgress
                                    size={20}
                                    color="inherit"
                                    sx={{ mr: 1 }}
                                />
                                Generating...
                            </>
                        ) : (
                            'Ask'
                        )}
                    </Button>
                </Paper>

                {answers.length > 0 && (
                    <>
                        {/* Latest Answer Section */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" gutterBottom>
                                Latest Answer
                            </Typography>
                            <ListItem
                                component={Paper}
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    bgcolor: 'rgba(25, 118, 210, 0.08)',
                                    borderRadius: 1,
                                    boxShadow: 3,
                                    border: '1px solid rgba(25, 118, 210, 0.25)',
                                }}
                            >
                                {/* Render first answer */}
                                {renderAnswer(answers[0], true)}
                            </ListItem>
                        </Box>

                        {/* Past Answers Section */}
                        {answers.length > 1 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Past Answers
                                </Typography>
                                <List sx={{ width: '100%' }}>
                                    {answers.slice(1).map((answer) => (
                                        <ListItem
                                            key={answer.id}
                                            component={Paper}
                                            sx={{
                                                mb: 2,
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: 1,
                                                boxShadow: 1,
                                            }}
                                        >
                                            {renderAnswer(answer, false)}
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                    </>
                )}
            </Box>
        </Container>
    );
};

export default Resources;
