import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import App from './App';
import { AuthProvider } from './components/AuthContext';
import { NotificationProvider } from './components/Notification';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container); // Create a root

root.render(
  <React.StrictMode>
    <AuthProvider>
      <NotificationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </NotificationProvider>
    </AuthProvider>
  </React.StrictMode>,
);
