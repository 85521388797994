import React, { createContext, useState, useEffect, ReactNode } from 'react';
import api from '../services/api';

interface AuthContextProps {
    isAuthenticated: boolean;
    user: User | null;
    isLoading: boolean;
    login: (token: string) => void;
    logout: () => void;
}

interface User {
    id: number;
    name: string;
    email: string;
    role: string;
    is_active: boolean;
    created_at: string;
    updated_at?: string;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    user: null,
    isLoading: true, // Initialize as loading
    login: () => { },
    logout: () => { },
});

interface Props {
    children: ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const login = (token: string) => {
        localStorage.setItem('access_token', token);
        fetchCurrentUser(token);
    };

    const logout = async () => {
        localStorage.removeItem('access_token');
        setUser(null);
        setIsAuthenticated(false);
    };

    const fetchCurrentUser = async (token: string) => {
        try {
            const response = await api.get('/users/me', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch current user', error);
            logout();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            fetchCurrentUser(token);
        } else {
            setIsLoading(false); // No token, stop loading
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!user,
                user,
                isLoading,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
