import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { NotificationContext } from '../components/Notification';

interface LoginFormInputs {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
    const navigate = useNavigate();
    const { login, isAuthenticated } = useContext(AuthContext);
    const { open: showNotification } = useContext(NotificationContext);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const onSubmit = async (data: LoginFormInputs) => {
        try {
            const params = new URLSearchParams();
            params.append('username', data.email);
            params.append('password', data.password);

            const response = await api.post('/auth/login', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            login(response.data.access_token);
            showNotification('Logged in successfully!', 'success');
            navigate('/');
        } catch (error: any) {
            showNotification(error.response?.data.detail || 'Login failed', 'error');
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        {...register('email', { required: 'Email is required' })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        {...register('password', { required: 'Password is required' })}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default Login;
